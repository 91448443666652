import { lazy } from 'react';

// project-imports
import MainLayout from 'layout/CompanyMainLayout';
//import CommonLayout from 'layout/CommonLayout';
// import CommonLayout from 'layout/CommonLayout';

import Loadable from 'components/Loadable';
import CompanyAuthGuard from 'utils/route-guard/CompanyAuthGuard';
import PermissionGuard from 'utils/route-guard/companyPermissionGuard';
const MaintenanceError = Loadable(lazy(() => import('pages/maintenance/error/404')));
const MaintenanceError500 = Loadable(lazy(() => import('pages/maintenance/error/500')));

const MaintenanceUnderConstruction = Loadable(lazy(() => import('pages/maintenance/under-construction/under-construction')));
const MaintenanceComingSoon = Loadable(lazy(() => import('pages/maintenance/coming-soon/coming-soon')));
const MaintenanceErrorFor = Loadable(lazy(() => import('pages/maintenance/error/404_forbidden')));

// render - sample page
const SamplePage = Loadable(lazy(() => import('pages/company/dashboard')));
const UserProfile = Loadable(lazy(() => import('pages/company/userprofile')));
// const  UserDocumentUpload= Loadable(lazy(() => import('pages/company/userdocumentuploaded')));
const UserDocumentList = Loadable(lazy(() => import('pages/company/DocumentManager/DocumentList')));
const AddLocation = Loadable(lazy(() => import('pages/location/LocationAdd')));
const SamplePage2 = Loadable(lazy(() => import('pages/extra-pages/sample-page2')));

//usermanagement
const ListOfSubUser = Loadable(lazy(() => import('pages/company/usermanagement/viewusers')));
const ViewSubUserActivity = Loadable(lazy(() => import('pages/company/usermanagement/viewcompanysubuseractivity')));

// Roles
const ListOfRoles = Loadable(lazy(() => import('pages/company/company_listrole')));
const AddRoles = Loadable(lazy(() => import('pages/company/company_addrole')));
const Permission = Loadable(lazy(() => import('pages/company/company_permission')));
const EditRoles = Loadable(lazy(() => import('pages/company/company_editrole')));

const Payments = Loadable(lazy(() => import('pages/company/payments')));

const Settings = Loadable(lazy(() => import('pages/company/settings')));
const UserActivity = Loadable(lazy(() => import('pages/company/useractivity')));
const LoginLogs = Loadable(lazy(() => import('pages/company/logs')));

const Payment = Loadable(lazy(() => import('pages/company/paymentreports')));
const Location = Loadable(lazy(() => import('pages/company/locationreports')));

const ViewLocation = Loadable(lazy(() => import('pages/company/LocationRequestManager/locationRequest')));

const ThankyouPage = Loadable(lazy(()=> import('pages/company/LocationRequestManager/thankyoupage')));
//Other Locations
const LCDWServices = Loadable(lazy(() => import('pages/company/OtherLocation/lcdwservices')));
const UtilitiesPlan = Loadable(lazy(() => import('pages/company/OtherLocation/utilitiesplan')));
const AuthoritiesApproval = Loadable(lazy(() => import('pages/company/OtherLocation/authoritiesapproval')));
const Survey = Loadable(lazy(() => import('pages/company/OtherLocation/servey')));
const GPRSurveys = Loadable(lazy(() => import('pages/company/OtherLocation/GPRsurveys')));
//Request list

const UtilitiesRequestList = Loadable(lazy(() => import('pages/company/LCDWRequest/UtilitiesPlanRequest')));
const LCDWRequestList = Loadable(lazy(() => import('pages/company/LCDWRequest/lcdwservice')));
const AuthoritiesRequestList = Loadable(lazy(() => import('pages/company/LCDWRequest/AuthoritiesApproval')));
const SurveyList = Loadable(lazy(() => import('pages/company/LCDWRequest/SurveyRequest')));
const GPRSurveysList = Loadable(lazy(() => import('pages/company/LCDWRequest/GPRSurveys')));

const LcdwView = Loadable(lazy(()=> import('pages/company/LCDWRequest/ViewRequest/lcdwView')));
const UtilityView = Loadable(lazy(()=> import('pages/company/LCDWRequest/ViewRequest/utilityView')));
const AuthorityView = Loadable(lazy(()=> import('pages/company/LCDWRequest/ViewRequest/authoritiesView')));
const SurveyView = Loadable(lazy(()=> import('pages/company/LCDWRequest/ViewRequest/surveyView')));
const GPRSurvey = Loadable(lazy(()=> import('pages/company/LCDWRequest/ViewRequest/gprsurveysView')));

const LocatinOrderView = Loadable(lazy(() => import('pages/company/LCDWRequest/ViewRequest/locationordersView')));
//assignedlocation
const ChangePassword = Loadable(lazy(() => import('pages/company/changepassword')));

const AssignedLocation = Loadable(lazy(()=> import('pages/company/assignedlocation/payment-request-list')));

//photoapproval
const PhotoApproval = Loadable(lazy(() => import('pages/company/photoApproval/photoApprList')));
const EditLocationData = Loadable(lazy(() => import('pages/company/photoApproval/EditLocationData')));
const ViewLocationData = Loadable(lazy(() => import('pages/company/photoApproval/ViewLocationData')));

const PaymentForm = Loadable(lazy(() => import('pages/company/LocationRequestManager/paymentform')));
const TemplateCms = Loadable(lazy(()=> import('pages/cms/template')));

const Invoices=Loadable(lazy(() => import('pages/company/assignedlocation/Paymentinvoice')));
// ==============================|| MAIN ROUTES ||============================== //
import { WidgetStatistics, PaginationTable } from 'pages/extra-pages/ViewLayout';

const ViewLayout = () => {
  return (
    <div>
      <WidgetStatistics />
      <PaginationTable />
    </div>
  );
};

const MainRoutes = {
  path: '/',
  children: [
    {
      path: '/:title',
      element: <TemplateCms/>
    },
    {
      path: '/',
      element: (
        <CompanyAuthGuard>
          <MainLayout />
        </CompanyAuthGuard>
      ),
      children: [
        {
          path: 'company/dashboard',
          element: <SamplePage />
        },
        {
          path: 'company/user-profile',
          element: (
            <PermissionGuard moduleId="userprofile">
              <UserProfile />
            </PermissionGuard>
          ),
        },
        {
          path: 'company/documentlist',
          element: (
            <PermissionGuard moduleId="documentmanager">
              <UserDocumentList />
            </PermissionGuard>
          ),
        },
        {
          path: 'company/location/add',
          // element: < AddLocation />
          element: (
            <PermissionGuard moduleId="locations">
              <AddLocation />
            </PermissionGuard>
          ),
        },
        {
          path: 'company/location/view',
          // element: <ViewLayout /> // Use AddTableLayout for adding data with a table
          element: (
            <PermissionGuard moduleId="locations">
              <ViewLayout />
            </PermissionGuard>
          ),
        },
        {
          path: 'company/location/photo-approval',
          // element: <SamplePage2 /> // Use AddTableLayout for adding data with a table
          element: (
            <PermissionGuard moduleId="locations">
              <SamplePage2 />
            </PermissionGuard>
          ),
        },
        {
          path: 'company/user-mangement',   // Admin User
          // element: <ListOfSubUser />
          element: (
            <PermissionGuard moduleId="user_management">
              <ListOfSubUser />
            </PermissionGuard>
          ),
        },
        {
          path: 'company/user-activity/:id',
          // element: <ViewSubUserActivity />
          element: (
            <PermissionGuard moduleId="user_management">
              <ViewSubUserActivity />
            </PermissionGuard>
          ),
        },
        {
          path: 'company/roles/list-of-roles',
          // element: <ListOfRoles />
          element: (
            <PermissionGuard moduleId="role_permission">
              <ListOfRoles />
            </PermissionGuard>
          ),
        },
        {
          path: 'company/roles/edit-roles/:id',
          // element: < EditRoles/>
          element: (
            <PermissionGuard moduleId="role_permission">
              <EditRoles />
            </PermissionGuard>
          ),
        },
        {
          path: 'company/roles/add-roles',
          // element: <AddRoles />
          element: (
            <PermissionGuard moduleId="role_permission">
              <AddRoles />
            </PermissionGuard>
          ),
        },
        {
          path: 'company/permission',
          // element: <Permission />
          element: (
            <PermissionGuard moduleId="role_permission">
              <Permission />
            </PermissionGuard>
          ),
        },
        {
          path: 'company/payments',
          // element: <Payments/>
          element: (
            <PermissionGuard moduleId="payments">
              <Payments />
            </PermissionGuard>
          ),
        },
        {
          path: 'company/settings',
          // element: < Settings />
          element: (
            <PermissionGuard moduleId="settings">
              <Settings />
            </PermissionGuard>
          ),
        },
        {
          path: 'company/reports/payment-reports',
          // element: < Payment />
          element: (
            <PermissionGuard moduleId="reports">
              <Payment />
            </PermissionGuard>
          ),
        },
        {
          path: 'company/reports/location-reports',
          // element: < Location />
          element: (
            <PermissionGuard moduleId="reports">
              <Location />
            </PermissionGuard>
          ),
        },
        {
          path: 'company/location-view',
          // element: < ViewLocation />
          element: (
            <PermissionGuard moduleId="view_location">
              <ViewLocation />
            </PermissionGuard>
          ),
        },
        {
          path: 'company/user-activity',
          // element: < UserActivity />
          element: (
            <PermissionGuard moduleId="useractivity">
              <UserActivity />
            </PermissionGuard>
          ),
        },
        {
          path: 'company/login-logs',
          // element: < LoginLogs />
          element: (
            <PermissionGuard moduleId="loginlogs">
              <LoginLogs />
            </PermissionGuard>
          ),
        },

        {
          path: 'company/view-invoices/:id',
          // element: <ViewLocation  />
          element: (
            // <PermissionGuard moduleId="Location">
              <Invoices />
            // </PermissionGuard>
          ),
        },
        {
          path: 'company/other-location/lcdw-service',
          // element: <LCDWServices />
          element: (
            <PermissionGuard moduleId="other_location">
              <LCDWServices />
            </PermissionGuard>
          ),
        },
        {
          path: 'company/other-location/utilities-plan',
          // element: <UtilitiesPlan />
          element: (
            <PermissionGuard moduleId="other_location">
              <UtilitiesPlan />
            </PermissionGuard>
          ),
        },
        {
          path: 'company/other-location/authorities-approval',
          // element: <AuthoritiesApproval />
          element: (
            <PermissionGuard moduleId="other_location">
              <AuthoritiesApproval />
            </PermissionGuard>
          ),
        },
        {
          path: 'company/other-location/survey',
          // element: <Survey />
          element: (
            <PermissionGuard moduleId="other_location">
              <Survey />
            </PermissionGuard>
          ),
        },
        {
          path: 'company/other-location/gpr-surveys',
          // element: <GPRSurveys />
          element: (
            <PermissionGuard moduleId="other_location">
              <GPRSurveys />
            </PermissionGuard>
          ),
        },
        {
          path: 'company/request/utilities-plan-request',
          // element: <UtilitiesRequestList />
          element: (
            <PermissionGuard moduleId="request_list">
              <UtilitiesRequestList />
            </PermissionGuard>
          ),
        },
        {
          path: '/company/request/lcdw-services-request',
          // element: <LCDWRequestList />
          element: (
            <PermissionGuard moduleId="request_list">
              <LCDWRequestList />
            </PermissionGuard>
          ),
        },
        {
          path: 'company/request/authorities-approval-request',
          // element: <AuthoritiesRequestList />
          element: (
            <PermissionGuard moduleId="request_list">
              <AuthoritiesRequestList />
            </PermissionGuard>
          ),
        },
        {
          path: 'company/request/survey-request',
          // element: <SurveyList />
          element: (
            <PermissionGuard moduleId="request_list">
              <SurveyList />
            </PermissionGuard>
          ),
        },
        {
          path: 'company/request/gpr-surveys-request',
          // element: <GPRSurveysList />
          element: (
            <PermissionGuard moduleId="request_list">
              <GPRSurveysList />
            </PermissionGuard>
          ),
        },
        {
          path: 'company/location-orders',
          // element: <AssignedLocation />
          element: (
            <PermissionGuard moduleId="location_orders">
              <AssignedLocation />
            </PermissionGuard>
          ),
        },
        {
          path: 'company/request/view-request/:id',
          element: <LcdwView />
        },
        {
          path: 'company/request/utility/view-request/:id',
          element: < UtilityView />
        },
        {
          path: 'company/request/authorities/view-request/:id',
          element: < AuthorityView />
        },
        {
          path: 'company/request/survey/view-request/:id',
          element: < SurveyView />
        },
        {
          path: 'company/request/GPR-survey/view-request/:id',
          element: < GPRSurvey />
        },
        {
          path: 'company/location-orders/:id',
          element: < LocatinOrderView />
        },
        {
          path: 'company/location-data',
          // element: <PhotoApproval />
          element: (
            <PermissionGuard moduleId="location_data">
              <PhotoApproval />
            </PermissionGuard>
          ),
        },
        {
          path: 'company/view-data/:id',
          element: <ViewLocationData />
         
        },
        {
          path: 'company/payment-form',
          element: (
            // <Elements stripe={stripePromise}>
              <PaymentForm />
            // </Elements>
          )
        },
        {
          path: 'company/thankyou-page',
          element: (
            <ThankyouPage />
          )
        },
        {
          path: 'company/edit-data/:id',
          element: <EditLocationData />
         
        },
        {
          path: 'company/change-password',
          element: < ChangePassword/>
        },
      ]
    },
    {
      path: '/maintenance',
      // element: <CommonLayout />,
      children: [
        {
          path: '404',
          element: <MaintenanceError />
        },
        {
          path: '500',
          element: <MaintenanceError500 />
        },
        {
          path: 'under-construction',
          element: <MaintenanceUnderConstruction />
        },
        {
          path: 'coming-soon',
          element: <MaintenanceComingSoon />
        },
        {
          path: '404_forbidden',
          element: <MaintenanceErrorFor />
        }
      ]
    }
  ]
};

export default MainRoutes;
