import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import React, { useEffect, useState, useCallback } from 'react';

// project-imports
import DrawerHeaderStyled from './DrawerHeaderStyled';
import { MenuOrientation } from 'config';


import { DRAWER_WIDTH, HEADER_HEIGHT } from 'config';
import useConfig from 'hooks/useConfig';
     
// import commonLogo from 'assets/images/common-logo.svg'
// import darkLogo from 'assets/images/dark_logo.svg'

// ==============================|| DRAWER HEADER ||============================== //

const DrawerHeader = ({ open }) => {
  const theme = useTheme();
  const downLG = useMediaQuery(theme.breakpoints.down('lg'));

  const { menuOrientation } = useConfig();
  const isHorizontal = menuOrientation === MenuOrientation.HORIZONTAL && !downLG;

  const [logoSrc, setLogoSrc] = useState('');

  const [currentMode, setCurrentMode] = useState(theme.palette.mode); // State to keep track of current mode

// Fetch logo when component mounts or theme mode changes
  const fetchLogo = useCallback(async () => {
    try {
      // Make a request to your backend to fetch the logo
      let response; // Declare response variable in outer scope

      if (currentMode === 'dark') {
        response = await axios.get('https://93.127.199.206/service/api/get-siteadmindark-logo/');
      } else {
        const type ='admin_logo';

        response = await axios.get(`https://93.127.199.206/service/api/get-siteadmin-logo/${type}`);
      }

      console.log(response.data.success);
      if (response.data.success) {
        const baseURL = 'https://93.127.199.206/service/';
        const imageURL = baseURL + response.data.siteSetting.meta_value;
        setLogoSrc(imageURL);
      } else {
        console.error('Failed to fetch logo:', response.data.error);
      }
    } catch (error) {
      console.error('Error fetching logo:', error);
    }
  });

  useEffect(() => {
    

    fetchLogo();
  }, [currentMode]); // Run effect when theme mode changes

  // Effect to reload page when theme mode changes
  useEffect(() => {
    const handleThemeChange = () => {
      setCurrentMode(theme.palette.mode);
      window.location.reload();
    };

    theme.palette.mode !== currentMode && handleThemeChange();

    return () => {
      theme.palette.mode !== currentMode && handleThemeChange();
    };
  }, [theme.palette.mode]); // Run effect when theme mode changes



  return (
    <DrawerHeaderStyled
      theme={theme}
      open={open}
      sx={{
        minHeight: isHorizontal ? 'unset' : HEADER_HEIGHT,
        width: isHorizontal ? { xs: '100%', lg: DRAWER_WIDTH + 50 } : 'inherit',
        paddingTop: isHorizontal ? { xs: '10px', lg: '0' } : '8px',
        paddingBottom: isHorizontal ? { xs: '18px', lg: '0' } : '8px',
        paddingLeft: isHorizontal ? { xs: '24px', lg: '0' } : open ? '24px' : 0
      }}
    >
      {/* <Logo isIcon={!open} sx={{ width: open ? 'auto' : 52, height: 'auto' }} /> */}

      <img src={logoSrc} style={{ width: open ? '100%' : '52px', height: 'auto', padding: '20px' }} alt="" />

    </DrawerHeaderStyled>
  );
};

DrawerHeader.propTypes = {
  open: PropTypes.bool
};

export default DrawerHeader;
