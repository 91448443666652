// material-ui imports
import React, { useMemo, useEffect, useState } from 'react';
import { Link  } from 'react-router-dom';
import { Grid, Box, Chip, Stack, Table, TableBody, TableCell, TableHead, TableRow, Button,  Tooltip, Typography, Select, FormControl, MenuItem } from '@mui/material';
import { Clock, Eye, Gps } from 'iconsax-react';
import RoundIconCard from 'components/cards/statistics/RoundIconCard';
import MainCard from 'components/MainCard';
import ScrollX from 'components/ScrollX';
// import LinearWithLabel from 'components/@extended/progress/LinearWithLabel';
import { TablePagination } from 'components/third-party/ReactTable';
import { useTable, useFilters, usePagination } from 'react-table';
import PropTypes from 'prop-types';
import eye from 'assets/images/eye_icon.svg';
import edit from 'assets/images/edit_icon.svg';

import pic from 'assets/images/location/image.svg';
import video from 'assets/images/location/video.svg';
import autocad from 'assets/images/location/autocad.svg';
import link from 'assets/images/location/link.svg';
import Swal from 'sweetalert2';
import 'assets/css/setting.css';

const WidgetStatistics = () => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} lg={4} sm={6}>
        <RoundIconCard
          primary="Total Locations "
          secondary="1,563"
          content="May 23 - June 01 (2018)"
          iconPrimary={Eye}
          color="primary.darker"
          bgcolor="primary.lighter"
        />
      </Grid>
      <Grid item xs={12} lg={4} sm={6}>
        <RoundIconCard
          primary="Recently Accessed Locations"
          secondary="50 (12.6%)"
          content="May 28 - June 01 (2018)"
          iconPrimary={Gps}
          color="success.darker"
          bgcolor="success.lighter"
        />
      </Grid>
      <Grid item xs={12} lg={4} md={12}>
        <RoundIconCard
          primary="Recently Updated Locations"
          secondary="20 (5.2%)"
          content="May 30 - June 01 (2018)"
          iconPrimary={Clock}
          color="warning.darker"
          bgcolor="warning.lighter"
        />
      </Grid>
    </Grid>
  );
};

function ReactTable({ columns, data, top }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    page,
    prepareRow,
    gotoPage,
    setPageSize,
    state: { pageIndex, pageSize }
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 }
    },
    useFilters,
    usePagination
  );

  return (
    <Stack>
      {top && (
        <Box sx={{ p: 2 }}>
          <TablePagination gotoPage={gotoPage} rows={rows} setPageSize={setPageSize} pageIndex={pageIndex} pageSize={pageSize} />
        </Box>
      )}
      <div style={{ overflowX: 'auto' }}>
        <Table {...getTableProps()}>
          <TableHead sx={{ borderTopWidth: top ? 2 : 1 }}>
            {headerGroups.map((headerGroup) => (
              <TableRow key={headerGroup} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <TableCell key={column} cell={column} {...column.getHeaderProps([{ className: column.className }])}>
                    {column.render('Header')}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <TableRow key={row} {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <TableCell key={cell} {...cell.getCellProps([{ className: cell.column.className }])}>
                      {cell.render('Cell')}
                    </TableCell>
                  ))}
                </TableRow>
              );
            })}
            {!top && (
              <TableCell sx={{ p: 2 }} colSpan={12}>
                <TablePagination gotoPage={gotoPage} rows={rows} setPageSize={setPageSize} pageIndex={pageIndex} pageSize={pageSize} />
              </TableCell>
            )}
          </TableBody>
        </Table>
      </div>
    </Stack>
  );
}

ReactTable.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  top: PropTypes.bool
};

const PaginationTable = () => {
  const [data, setUserDetails] = useState([]);


  useEffect(() => {
    makeData();
  }, []);

  const storedUserDetails = localStorage.getItem('user');
  const user = storedUserDetails ? JSON.parse(storedUserDetails) : null;
  const user_id = user ? user._id : null;
  const user_email = user ? user.email : null;
  const user_name_v = user ? user.name : null;
  const user_type = user ? user.login_type : null;

  const handleDelete = (id) => {
    // Display a confirmation dialog using SweetAlert
    Swal.fire({
      title: 'Are you sure?',
      text: 'Do You Want To Delete! the user!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      customClass: {
        confirmButton: 'swal-confirm-button-class' // Add custom class to the confirm button
      }
    }).then((result) => {
      if (result.isConfirmed) {
        // User confirmed, send the DELETE request
        axios
          .delete(`https://93.127.199.206/service/api/delete-location/${id}`, {
            params: {
              created_by: user_id,
              login_user_email: user_email,
              login_user_name: user_name_v,
              login_user_type: user_type
            }
          })
          .then((res) => {
            Swal.fire({
              icon: 'success',
              title: 'Success',
              text: res.data.message,
              customClass: {
                confirmButton: 'swal-confirm-button-class' // Add custom class to the confirm button
              }
            });
            makeData();
          })
          .catch((err) => {
            console.error('Error deleting user:', err);
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'Failed to delete. Please try again.',
              customClass: {
                confirmButton: 'swal-confirm-button-class'
              }
            });
          });
      }
    });
  };

  const makeData = async () => {
    try {
      const response = await axios.get('https://93.127.199.206/service/api/list-location');
      const userData = response.data.location;

      const locationList = await Promise.all(
        userData.map(async (user, index) => {
          console.log('check status', user.status);
          const getid = user._id;
          const location_unique_id = user.location_unique_id;
      
          const getLocationCost = await axios.post('https://93.127.199.206/service/api/get-location-cost', {
            getid: getid,
            location_unique_id: location_unique_id
          });

          const LocationPricing = getLocationCost.data.LocationPricing || '';
          const locationCurrency = getLocationCost.data.locationCurrency.pricing_currency || '';
          const pictureCount = getLocationCost.data.pictureTitleCount || 0;
          const videoCount = getLocationCost.data.videoTitleCount || 0;
          const autocadCount = getLocationCost.data.autocadTitleCount || 0;
          const otherCount = getLocationCost.data.otherTitleCount || 0;
          const locationmapval = getLocationCost.data.updatedLocationData || '';
          const truncatedLatitude = parseFloat(locationmapval.latitude).toFixed(2);
          const truncatedLongitude = parseFloat(locationmapval.longitude).toFixed(2);
          const mapval = `Lat: ${truncatedLatitude} Long: ${truncatedLongitude}`;

          //addedby getnamd
          let addedUser;
          if (user.login_user_type === 'admin') {
            addedUser = 'Admin User';
          } else if (user.login_user_type === 'staff_user') {
            addedUser = 'Staff User';
          } else if (user.login_user_type === 'super_dmin'){
            addedUser = 'Admin';
          }

          function reformatDate(dateString) {
            // Split the date string to get the date part
            const [datePart] = dateString.split(' ');
            // Replace hyphens with slashes
            return datePart.replace(/-/g, '/');
          }

          try {
            return {
              id: userData.length - index,
              getid: getid, 
              locationName: `${user.location_title}, ${mapval}`,
              city: `${user.city}, ${user.postal_code}`,
              cost: `${locationCurrency} ${LocationPricing}`,
              totalRequests: '-',
              totalViews: '-',
              availabledata: { pictureCount, videoCount, autocadCount, otherCount },
              addedby: addedUser,
              addedon: reformatDate(user.create_date),
              status: user.status
            };
          } catch (error) {
            console.error(error);
          }
        })
      );
      console.log('check details',locationList);
      setUserDetails(locationList);
    } catch (error) {
      console.error('Error fetching location:', error);
    }
  };

  const handleStatusChange = (event, row) => {
    const { value } = event.target;
    const user_id = row.original.getid;
    if (value === 'Active') {
      handleChangeStatus(user_id, 1);
    } else if (value === 'Inactive') {
      handleChangeStatus(user_id, 0);
    }
  };

  const handleChangeStatus = async (user_id, status) => {
    // console.log(`Updating user ${user_id} status to ${status}`);

    try {
      const response = await fetch(`https://93.127.199.206/service/api/location-status-updated/${user_id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          status,
          created_by: user_id,
          login_user_email: user_email,
          login_user_name: user_name_v,
          login_user_type: user_type,
        }),
      });
      makeData();
      if (!response.ok) {
        throw new Error('Failed to update status');
      }
      Swal.fire({
        title: "Status Updated",
        text: "Status changed successfully!",
        icon: "success",
        customClass: {
          confirmButton: 'swal-confirm-button-class'
        }
      });
      return true;
    } catch (error) {
      console.error('Error updating status in database:', error);
      return false;
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Location Name',
        accessor: 'locationName',
        Cell: ({ value }) => {
          const [location, mapval] = value.split(', ');
          return (
            <div>
              <div>{location}</div>
              <div style={{ color: 'rgba(172, 172, 172, 1)', fontSize: '14px', fontWeight: '500' }}>{mapval}</div>
            </div>
          );
        }
      },
      {
        Header: 'City',
        accessor: 'city',
        Cell: ({ value }) => {
          const [city, pinCode] = value.split(', ');
          return (
            <div>
              <div>{city}</div>
              <div style={{ color: 'rgba(172, 172, 172, 1)', fontSize: '14px', fontWeight: '500' }}>{pinCode}</div>
            </div>
          );
        }
      },
      {
        Header: 'Cost',
        accessor: 'cost'
      },
      {
        Header: 'Total Requests',
        accessor: 'totalRequests',
        // className: 'cell-right'
      },
      {
        Header: 'Total Views',
        accessor: 'totalViews',
        // className: 'cell-right'
      },
      {
        Header: 'Available data',
        accessor: 'availabledata',
        Cell: ({value }) => (
         
          <Grid sx={{ display: 'flex', gap: '10px', size: '10px', width: '24px', height: '24px' }}>
            {value.pictureCount > 0 && <img src={pic} alt="img" />}
            {value.videoCount > 0 && <img src={video} alt="img" />}
            {value.autocadCount > 0 && <img src={autocad} alt="img" />}
            {value.otherCount > 0 && <img src={link} alt="img" />}
          </Grid>
        )
      },

      {
        Header: 'Added by',
        accessor: 'addedby',
        // className: 'cell-right'
      },

      {
        Header: 'Added on',
        accessor: 'addedon',
        // className: 'cell-right'
      },

      {
        Header: 'status',
        accessor: 'status',
        // className: 'cell-right',
        disableFilters: true,
        filter: 'includes',
        Cell: ({ value }) => {
          console.log('check status', value);
          switch (value) {
            case '0':
              return <Chip color="error" label="In Active" size="small" variant="light" />;
            case '1':
              return <Chip color="success" label="Active" size="small" variant="light" />;
            default:
              return <Chip color="info" label="Default" size="small" variant="light" />;
          }
        }
      },

      {
        Header: 'action',
        accessor: '',
        // className: 'cell-right',
        Cell: ({ row }) => {
          const getid = row.original.getid;
          const status = row.original.status;
          return (
            <Stack direction="row" alignItems="center" justifyContent="center" spacing={1}>
              {/* View Button */}
              <Tooltip title="View">
                <Link to={`/admin/location/view/${getid}`}>
                  <Typography>
                    <img src={eye} alt="View" />
                  </Typography>
                </Link>
              </Tooltip>
      
              {/* Edit Button */}
              <Tooltip title="Edit">
              <Link to={`/admin/location/edit/${getid}`} className='linkStyle'>
                <Typography onClick={() => console.log('Edit action triggered')}>
                  <img src={edit} alt="Edit" />
                </Typography>
              </Link>
              </Tooltip>
      
              <FormControl fullWidth>
                <Select
                  labelId="demo-simple-select-label"
                  displayEmpty
                  id="demo-simple-select"
                  value=""
                  placeholder="Another Action"
                  onChange={(event) => handleStatusChange(event, row)}
                  sx={{
                    height: 30,
                    width: 100,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginBottom: '6px',
                  }}
                  MenuProps={{
                    sx: {
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    },
                  }}
                >
                  <MenuItem  disable value="" sx={{ color: 'text.secondary' }}>
                    Action
                  </MenuItem>
                  {status == 0 ? (
                    <MenuItem value="Active">Active</MenuItem>
                  ) : (
                    <MenuItem value="Inactive">Inactive</MenuItem>
                  )}
                  {/* <MenuItem value={10} sx={{ paddingLeft: 2 }} onClick={''}>
                    <label htmlFor="deleteAction">
                      <Typography id="deleteAction">Delete</Typography>
                    </label>
                  </MenuItem> */}
                  <MenuItem
                    value={1}
                    sx={{ paddingLeft: 2, backgroundColor: '#ffcccc', color: 'black' }}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDelete(getid);
                    }}
                  >
                    <label htmlFor="deleteAction">
                      <Typography id="deleteAction">Delete</Typography>
                    </label>
                  </MenuItem>
                  <MenuItem value={40} sx={{ paddingLeft: 2 }} onClick={''}>
                    {/* <label htmlFor="resetPasswordAction">
                      <Typography id="resetPasswordAction">Reset Password</Typography>
                    </label> */}
                  </MenuItem>
                </Select>
              </FormControl>
            </Stack>
          );
        },
      }
    ],
    []
  );

  return (
    <Grid container spacing={3} sx={{ marginTop: '20px' }}>
      <Grid item xs={12}>
        <MainCard
          title="Location List"
          content={false}
          secondary={
            <Link to="/admin/location/add" underline="none">
              <Button variant="contained" color="info" sx={{ borderRadius: '20px', marginLeft: '10px', width: '80px' }}>
                ADD
              </Button>
            </Link>
          }
        >
          <ScrollX>
            <ReactTable columns={columns} data={data} bottom />
          </ScrollX>
        </MainCard>
      </Grid>
    </Grid>
  );
};

PaginationTable.propTypes = {
  value: PropTypes.string
};

export { WidgetStatistics, PaginationTable };
