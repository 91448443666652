import { lazy } from 'react';

// project-imports
import MainLayout from 'layout/LcdwMainLayout';
//import CommonLayout from 'layout/CommonLayout';
import Loadable from 'components/Loadable';
import LcdwAuthGuard from 'utils/route-guard/LcdwAuthGuard';


// render - sample page
const SamplePage = Loadable(lazy(() => import('pages/lcdw/dashboard')));
const SamplePage2 = Loadable(lazy(() => import('pages/extra-pages/sample-page2')));
const UserProfile = Loadable(lazy(() => import('pages/lcdw/userprofile')));
const RequestList = Loadable(lazy(() => import('pages/lcdw/requestlist')));
const BillingRequest = Loadable(lazy(() => import('pages/lcdw/billingpayment')));
const ChangePassword = Loadable(lazy(() => import('pages/lcdw/changepassword')));
const Activity = Loadable(lazy(() => import('pages/lcdw/action')));
const Logs = Loadable(lazy(() => import('pages/lcdw/logs')));


//location
const AddLocation = Loadable(lazy(() => import('pages/lcdw/lcdwLocationAdd')));
const EditLocation = Loadable(lazy(() => import('pages/lcdw/lcdwLocationEdit')));
const ViewLocation = Loadable(lazy(() => import('pages/lcdw/lcdwLocationView')));


//request list
const UtilitiesRequest = Loadable(lazy(() => import('pages/lcdw/LCDWRequest/UtilitiesPlanRequest')));
const LCDWRequest = Loadable(lazy(() => import('pages/lcdw/LCDWRequest/Requestmain')));
const AuthoritiesRequest = Loadable(lazy(() => import('pages/lcdw/LCDWRequest/AuthoritiesApproval')));
const Survey = Loadable(lazy(() => import('pages/lcdw/LCDWRequest/SurveyRequest')));
const GPRSurveys = Loadable(lazy(() => import('pages/lcdw/LCDWRequest/GPRSurveys')));

//new request list

const NewRequestList = Loadable(lazy(()=> import('pages/lcdw/LCDWRequest/NewRequestMain')));
// request add
const LCDWRequestAdd = Loadable(lazy(() => import('pages/lcdw/LCDWRequest/lcdwAdd')));
const UtilitiesRequestAdd = Loadable(lazy(() => import('pages/lcdw/LCDWRequest/utilityAdd')));
const AuthoritiesRequestAdd = Loadable(lazy(() => import('pages/lcdw/LCDWRequest/authoritiesAdd')));
const SurveyAdd = Loadable(lazy(() => import('pages/lcdw/LCDWRequest/surveyAdd')));
const GPRSurveysAdd = Loadable(lazy(() => import('pages/lcdw/LCDWRequest/gprsurveyAdd')));

//edit request
const LCDWRequestEdit = Loadable(lazy(() => import('pages/lcdw/LCDWRequest/EditData/lcdwEditData')));
const LCDWRequestDataAdd = Loadable(lazy(() => import('pages/lcdw/LCDWRequest/EditData/lcdwAddData')));

// LCDW USER
// const ListOfLCDWUsers=Loadable(lazy(() => import('pages/user/lcdwuser/view')));
// const ViewLCDWUserActivity=Loadable(lazy(() => import('pages/user/lcdwuser/viewlcdwuseractivity')));

//request view
const LCDWRequestView = Loadable(lazy(() => import('pages/lcdw/LCDWRequest/ViewRequest/lcdwView')));
const UtilitiesRequestView = Loadable(lazy(() => import('pages/lcdw/LCDWRequest/ViewRequest/utilityView')));
const AuthoritiesRequestView = Loadable(lazy(() => import('pages/lcdw/LCDWRequest/ViewRequest/authoritiesView')));
const SurveyView = Loadable(lazy(() => import('pages/lcdw/LCDWRequest/ViewRequest/surveyView')));
const GPRSurveysView = Loadable(lazy(() => import('pages/lcdw/LCDWRequest/ViewRequest/gprsurveysView')));
const ViewLocationRequest = Loadable(lazy(() => import(`pages/lcdw/LCDWRequest/viewData`)));

//Billing & payments
const WithdrawalList = Loadable(lazy(() => import('pages/lcdw/Billing & payments/withdrawallist')));
const BankList = Loadable(lazy(() => import('pages/lcdw/Billing & payments/banklist')));
const TemplateCms = Loadable(lazy(()=> import('pages/cms/template')));


const WithdrawalInvoice = Loadable(lazy(()=> import('pages/lcdw/Billing & payments/Paymentinvoice')));
import { WidgetStatistics, PaginationTable } from 'pages/extra-pages/lcdw_ViewLayout';

// ==============================|| MAIN ROUTES ||============================== //
const  ViewLayout = () => {
  return (
    <div>
      <WidgetStatistics />
      <PaginationTable />
    </div>
  );
};

const MainRoutes = {
  path: '/',
  children: [
    {
      path: '/:title',
      element: <TemplateCms/>
    },
    {
      path: '/',
      element: (
        <LcdwAuthGuard>
          <MainLayout />
        </LcdwAuthGuard>
      ),
      children: [
        {
          path: 'lcdw/dashboard',
          element: <SamplePage />
        },
        {
          path: 'lcdw/user-profile',
          element: < UserProfile />
        },
        {
          path: 'lcdw/location/list',
          element: <ViewLayout /> // Use AddTableLayout for adding data with a table
        },
        {
          path: 'lcdw/location/photo-approval',
          element: <SamplePage2 /> // Use AddTableLayout for adding data with a table
        },
        {
          path: 'lcdw/request/view-data/:id',
          element: <ViewLocationRequest  />
        },
        {
          path: 'lcdw/location/list',
          element: <ViewLayout /> // Use AddTableLayout for adding data with a table
        },
        {
          path: 'lcdw/location/add',
          element: <AddLocation  /> // Use AddTableLayout for adding data with a table
        },
        {
          path: 'lcdw/location/edit/:id',
          element: <EditLocation  />
        },
        {
          path: 'lcdw/location/view/:id',
          element: <ViewLocation  />
        },
        {
          path: 'lcdw/request-list',
          element: < RequestList />
        },
        {
          path: 'lcdw/billing-payment',
          element: < BillingRequest />
        },
        {
          path: 'lcdw/change-password',
          element: < ChangePassword/>
        },
        {
          path: 'lcdw/activity',
          element: < Activity />
        },
        {
          path: 'lcdw/login-logs',
          element: < Logs />
        },
        {
          path: 'lcdw/request/utilities-plan-request',
          element: <UtilitiesRequest />
        },
        {
          path: '/lcdw/request/lcdw-services-request',
          element: <LCDWRequest />
        },
        {
          path: '/lcdw/request/lcdw-new-request-list',
          element: < NewRequestList/>
        },
        {
          path: 'lcdw/request/authorities-approval-request',
          element: <AuthoritiesRequest />
        },
        {
          path: 'lcdw/request/survey-request',
          element: <Survey />
        },
        {
          path: 'lcdw/request/gpr-surveys-request',
          element: <GPRSurveys />
        },
        {
          path: 'lcdw/request/lcdw-services-request/lcdw-add',
          element: <LCDWRequestAdd />
        },
        {
          path: 'lcdw/request/utilities-plan-request/utilities-add',
          element: <UtilitiesRequestAdd />
        },
        {
          path: 'lcdw/request/authorities-approval-request/authorities-add',
          element: <AuthoritiesRequestAdd />
        },
        {
          path: 'lcdw/request/survey-request/survey-add',
          element: <SurveyAdd />
        },
        {
          path: 'lcdw/request/gpr-surveys-request/gpr-surveys-add',
          element: <GPRSurveysAdd />
        },
        {
          path: 'lcdw/request/lcdw-services-request/add-data/:firstId',
          element: <LCDWRequestDataAdd />
        },
        {
          path: 'lcdw/request/lcdw-services-request/edit-data/:firstId/:secondId',
          element: <LCDWRequestEdit />
        },
        {
          path: 'lcdw/request/utilities/view-request/:id',
          element: <UtilitiesRequestView />
        },
        {
          path: 'lcdw/request/lcdw/view-request/:id',
          element: <LCDWRequestView />
        },
        {
          path: 'lcdw/request/authorities/view-request/:id',
          element: <AuthoritiesRequestView />
        },
        {
          path: 'lcdw/request/survey/view-request/:id',
          element: <SurveyView />
        },
        {
          path: 'lcdw/request/gprsurveys/view-request/:id',
          element: <GPRSurveysView />
        },
        {
          path: 'lcdw/withdrawallist',
          element: <WithdrawalList />
        },
        {
          path: 'lcdw/banklist',
          element: <BankList />
        },
        {
          path: 'lcdw/withdrawal-invoice/:id',
          element: <WithdrawalInvoice />
        },
       
      ]
    },
   
  ]
};

export default MainRoutes;
