// project-imports
import support from './support';
import location from './location';
import request from './request';
import payment from './payment';
import report from './report';
import cms from './cms';
import setting from './setting';
import feedback from './feedback';
import logs from './logs';
// import mail from './email';

// lcdw-imports
import lcdw from './lcdw';

// company-imports
import company from './company';

// ==============================|| MENU ITEMS ||============================== //

// Define menu items for each login type
const menuItemsByRole = {
  admin: [support, location, request, payment, report, cms, setting, feedback, logs],
  company: [company],
  lcdw: [lcdw]
};

// Check if loginType is set properly in sessionStorage
let loginType = sessionStorage.getItem('loginType');

// If loginType is not set in sessionStorage, retrieve it from localStorage
if (loginType === null) {
  loginType = localStorage.getItem('logintype');
  if (loginType) {
    // Set the loginType into sessionStorage for the current session
    sessionStorage.setItem('loginType', loginType);
  } else {
    console.error('loginType is missing from both sessionStorage and localStorage');
    // Handle the case where loginType is missing
    // window.location.href = '/login'; // Redirect to login page if needed
  }
}

// Set the items array based on the loginType
const items = menuItemsByRole[loginType] || []; // Fallback to an empty array if loginType is invalid

// Define the menu items object
const menuItems = {
  items
};

export default menuItems;