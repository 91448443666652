import { useEffect, useState, lazy } from 'react';
import { useNavigate } from 'react-router-dom';

// project-imports
import Routes from 'routes';
import ThemeCustomization from 'themes';
//import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
// import Loader from 'components/Loader';

import LandingPage from 'components/LandingPage';

import ContactUs from 'pages/dashboard/mainContact';
import { AppNameProvider } from 'contexts/AppNameContext';

import Locales from 'components/Locales';
//import RTLLayout from 'components/RTLLayout';
import ScrollTop from 'components/ScrollTop';
import Snackbar from 'components/@extended/Snackbar';
import Notistack from 'components/third-party/Notistack';

// import { dispatch } from 'store';
// import { fetchMenu } from 'store/reducers/menu';

// auth-provider
import { JWTProvider as AuthProvider } from 'contexts/JWTContext';
import { JWTProvider as CompanyProvider } from 'contexts/CompanyJWTProvider';
import { JWTProvider as LcdwProvider } from 'contexts/LcdwJWTProvider';
//  import Loadable from 'components/Loadable';
// import { lazy } from 'react';
// import { FirebaseProvider as AuthProvider } from 'contexts/FirebaseContext';
// import { AWSCognitoProvider as AuthProvider } from 'contexts/AWSCognitoContext';
// import { Auth0Provider as AuthProvider } from 'contexts/Auth0Context';
const TemplateCms = lazy(() => import('pages/cms/landingTemplate'));

// ==============================|| APP - THEME, ROUTER, LOCAL  ||============================== //
// const PagesLanding = Loadable(lazy(() => import('./components/LandingPage')));

const App = () => {
  // const [loading, setLoading] = useState(true);
  const [loginType, setLoginType] = useState('landingPage');
  const navigate = useNavigate();

  var logintype = localStorage.getItem('logintype');
  const url = window.location.pathname;

  useEffect(() => {
    if (logintype == 'admin') {
      setLoginType('admin');

      if (url.startsWith('/company') || url.startsWith('/lcdw')) {
        navigate('/admin', { replace: true });
      }

    } else if (logintype == 'company') {

      setLoginType('company');

      if (url.startsWith('/admin') || url.startsWith('/lcdw')) {
        navigate('/company', { replace: true });
      }

    } else if (logintype == 'lcdw') {

      setLoginType('lcdw');

      if (url.startsWith('/admin') || url.startsWith('/company')) {
        navigate('/lcdw', { replace: true });
      }

    } else {

      if (url.startsWith('/admin')) {
        setLoginType('admin');
      } else if (url.startsWith('/company')) {
        setLoginType('company');
      } else if (url.startsWith('/lcdw')) {
        setLoginType('lcdw');
      } else if (url.startsWith('/Contact-us')) {
        setLoginType('contact');
      } else if (url.match(/\/[^/]+/)) { // Match dynamic title paths
        setLoginType('dynamic');
      } else {
        setLoginType('landingPage');
      }

    }
    /* multiple login logic end */




    // dispatch(fetchMenu()).then(() => {
    //   setLoading(false);
    // });
  });

  const renderAuthProvider = () => {
    switch (loginType) {

      // ADMIN ROUTES
      case 'admin':
        return <ThemeCustomization>
          {/* <RTLLayout> */}
          <Locales>
            <ScrollTop>
              <AuthProvider>
                <AppNameProvider>
                  <>
                    <Notistack>
                      <Routes loginType={loginType} />
                      <Snackbar />
                    </Notistack>
                  </>
                </AppNameProvider>
              </AuthProvider>
            </ScrollTop>
          </Locales>
          {/* </RTLLayout> */}
        </ThemeCustomization>;

      // COMPANY ROUTES
      case 'company':
        return <ThemeCustomization>
          {/* <RTLLayout> */}
          <Locales>
            <ScrollTop>
              <CompanyProvider>
                <AppNameProvider>
                  <>
                    <Notistack>
                      <Routes loginType={loginType} />
                      <Snackbar />
                    </Notistack>
                  </>
                </AppNameProvider>
              </CompanyProvider>
            </ScrollTop>
          </Locales>
          {/* </RTLLayout> */}
        </ThemeCustomization>;

      // LCDW ROUTES
      case 'lcdw':
        return <ThemeCustomization>
          {/* <RTLLayout> */}
          <Locales>
            <ScrollTop>
              <LcdwProvider>
                <AppNameProvider>
                  <>
                    <Notistack>
                      <Routes loginType={loginType} />
                      <Snackbar />
                    </Notistack>
                  </>
                </AppNameProvider>
              </LcdwProvider>
            </ScrollTop>
          </Locales>
          {/* </RTLLayout> */}
        </ThemeCustomization>;

      // CONTACT-US ROUTE
      case 'contact':
        return <ContactUs />;
      case 'dynamic':
        return <TemplateCms title={url.split('/')[1]} />;

      default:
        return <LandingPage />;
    }
  };

  // if (loading) return <Loader />;

  return (
    renderAuthProvider()
  );
};

export default App;



